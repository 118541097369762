import FormatCurrency from 'components/Common/FormatCurrency'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import withMountGuard from 'hocs/withMountGuard'
import { useAppSelector } from 'hooks/reduxHooks'
import { themeClassName } from 'lib/theme/themeUtils'
import LuxPlusLogo from 'luxPlus/components/Logos/LuxPlusLogo'
import { LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import getLuxPlusTotalValueSaved from 'luxPlus/selectors/benefits/getLuxPlusTotalValueSaved'
import FormatDateTime from 'components/utils/Formatters/FormatDateTime'
import Caption from 'components/Luxkit/Typography/Caption'
import Divider from 'components/Luxkit/Divider'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'

const Container = styled.div`
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  padding: ${rem(24)} ${rem(24)} ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.M};
`

const SubContainer = styled.div`
  background-color: rgba(255, 255, 255, .1);
  padding: ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.S};
`

const AccountSnapshotContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
`

const LuxPlusDivider = styled(Divider)`
  background-color: ${props => props.theme.palette.product.luxPlus.background} !important; // One off instance
`

const LuxPlusLoadingBox = styled(TextLoadingBox)`
  opacity: 0.2;
`

interface Props {
  onLinkClick: () => void
}

function AccountMenuLuxPlusDetailsTile({ onLinkClick }: Props) {
  const totalValueSaved = useAppSelector(getLuxPlusTotalValueSaved)
  const luxPlusSubscription = useAppSelector(state => state.auth.account.luxPlus.member.subscription)
  const isSubscribedLuxPlusMember = useAppSelector(checkCanRedeemLuxPlusBenefits)

  if (luxPlusSubscription.fetching) {
    return <Container>
      <Group direction="vertical" gap={16}>
        <div>
          <LuxPlusLogo colour="inherit" height={16} />
        </div>
        <LuxPlusLoadingBox width="100%" lines={1} typography="body-large" />
        <Group direction="horizontal-reverse">
          <div>
            <LuxPlusLoadingBox width="16ch" lines={1} typography="body-small" />
          </div>
        </Group>
      </Group>
    </Container>
  }

  if (!isSubscribedLuxPlusMember || luxPlusSubscription.error) return null

  return <Container className={themeClassName('inverse')}>
    <Group direction="vertical" gap={12}>
      <div>
        <LuxPlusLogo colour="inherit" height={16} />
      </div>
      <SubContainer>
        {!totalValueSaved && <Caption variant="medium" align="center">
          Enjoy member benefits all year round
        </Caption>}
        {!!totalValueSaved && <AccountSnapshotContainer>
          <Group direction="vertical" gap={2} horizontalAlign="center">
            <Caption variant="medium">
              Savings
            </Caption>
            <Heading variant="heading6" colour="neutral-one">
              <FormatCurrency value={totalValueSaved} format="roundedDollar" />
            </Heading>
          </Group>
          <div>
            <LuxPlusDivider kind="primary" direction="vertical" />
          </div>
          <Group direction="vertical" gap={2} horizontalAlign="center">
            <Caption variant="medium">
              Join date
            </Caption>
            <Heading variant="heading6" colour="neutral-one">
              <FormatDateTime value={luxPlusSubscription.item!.memberSince} year="2-digit" month="short" day="2-digit" />
            </Heading>
          </Group>
        </AccountSnapshotContainer>}
      </SubContainer>
      <Group direction="horizontal-reverse">
        <TextButton
          kind="tertiary"
          size="small"
          endIcon={<LineAngleRightIcon />}
          to={`/${LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE}`}
          onClick={onLinkClick}
        >
          View account
        </TextButton>
      </Group>
    </Group>
  </Container>
}

export default withMountGuard(() => {
  return useAppSelector(isLuxPlusEnabled)
})(AccountMenuLuxPlusDetailsTile)
