import cn from 'clsx'
import Badge from 'components/Luxkit/Badge'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import { SVG_ICON_SIZE_CSS_VAR } from 'components/Luxkit/SvgIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React from 'react'
import { hasAccountNotification } from 'selectors/accountSelectors'
import styled from 'styled-components'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

const IconContainer = styled.div`
  position: relative;
`

const NotificationBadge = styled(Badge)`
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-100%) translateX(50%);
  right: 0;
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(24)};
  width: ${rem(24)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.neutral.default.one};
  ${SVG_ICON_SIZE_CSS_VAR}: ${rem(16)};

  ${mediaQueryUp.tablet} {
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(20)};
    height: ${rem(32)};
    width: ${rem(32)};
  }

  &.lux-plus-member {
    background-color: ${props => props.theme.palette.product.luxPlus.background};
  }
`

function NavBarUserAvatar() {
  const account = useAppSelector(state => state.auth.account)
  const isLuxPlusMember = useAppSelector(state => checkCanRedeemLuxPlusBenefits(state))

  const showNotification = useAppSelector(hasAccountNotification)

  const intials = account.givenName && account.surname ? account.givenName[0] + account.surname[0] : undefined

  return <IconContainer>
    <Avatar className={cn({ 'lux-plus-member': isLuxPlusMember })}>
      {intials && <Caption variant="large" format="uppercase" weight="bold" colour="neutral-eight">
        {intials}
      </Caption>}
      {!intials && <LineUserIcon colour="neutral-eight" />}
    </Avatar>
    {showNotification && <NotificationBadge
      mode="critical"
      kind="dot"
    />}
  </IconContainer>
}

export default React.memo(NavBarUserAvatar)
