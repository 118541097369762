import LayoutContainer from 'components/Common/LayoutContainer'
import { mediaQueryDown, mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import { rem } from 'polished'
import React, { useCallback, useContext, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import Logo from '../Logo'
import cn from 'clsx'
import NavigationHeader from './NavigationHeader/NavigationHeader'
import IconButton from 'components/Luxkit/Button/IconButton'
import { isMobileSearchEnabled, isSearchEnabled } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import LineBarsIcon from 'components/Luxkit/Icons/line/LineBarsIcon'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import SearchToggle from './SearchToggle'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import NavigationHeaderTripsMenu from './NavigationHeader/NavigationHeaderTripsMenu'
import HeaderContactOptionsMenu from '../HeaderContactOptions/HeaderContactOptionsMenu'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import BusinessTravellerAccountIconButtonMenu from 'businessTraveller/components/business-account/BusinessTravellerAccountIconButtonMenu'
import HeaderUserAvatar from './HeaderUserAvatar'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { Location } from 'history'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import useCurrentGlobalSearchVerticalPage from 'hooks/GlobalSearch/useCurrentGlobalSearchVerticalPage'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import { searchNavCTAClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import ModalContext from 'contexts/ModalContext'
import NavigationMenu from './NavigationMenu/NavigationMenu'
import TextButton from 'components/Luxkit/Button/TextButton'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { checkCanRedeemLuxPlusBenefits, isFreePreviewLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import AgentHubAccountMenu from 'agentHub/components/AgentHubAccountMenu'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import Group from 'components/utils/Group'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import { LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import LineSuitcaseIcon from 'components/Luxkit/Icons/line/LineSuitcaseIcon'
import * as Analytics from 'analytics/analytics'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'
import HeaderNotification from './HeaderNotification'
import MultiUserCartHeader from './MultiCartHeader'
import { capitalise } from 'lib/string/stringUtils'
import { isMultiCartEnabled } from 'selectors/featuresSelectors'

const HeaderBarSheet = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};

  @media print {
    a[href]:after {
      content: "";
    }
  }
`

const HeaderContainer = styled(LayoutContainer)`
  position: relative;
  ${config.MOBILE_APP_CUSTOMISATIONS ? `padding-top: ${rem(50)};` : ''}
`

const MainHeader = styled.div`
  display: grid;
  align-items: center;
  height: ${rem(72)};
  grid-template-columns: auto minmax(0px, auto) 1fr;
  grid-column-gap: ${rem(16)};

  ${mediaQueryDown.mobile} {
    height: ${rem(64)};
    &.hide-hamburger {
      grid-template-columns: auto 1fr;
    }

  }

  ${mediaQueryOnly.tablet} {
    &.active-lux-plus-member {
      grid-column-gap: ${rem(4)};
    }
  }

  ${mediaQueryUp.tablet} {
    grid-template-columns: auto 1fr auto;

    &:not(.search-enabled){
      &.hide-hamburger {
        grid-template-columns: 1fr auto;
      }
    }

    &:not(.hide-hamburger){
      &.search-enabled {
        grid-template-columns: auto auto 1fr auto;
      }
    }
  }

  ${mediaQueryUp.largeDesktop} {
    grid-column-gap: ${rem(20)};
    grid-template-columns: 1fr auto;

    &:not(.hide-hamburger){
      &.search-enabled {
        grid-template-columns: auto 1fr auto;
      }
    }

    &.search-enabled {
      grid-template-columns: auto 1fr auto;
    }
  }
`

const HeaderLogo = styled(Logo)`
  align-items: center;

  ${mediaQueryDown.mobile} {
    flex-grow: 1;
    ${config.BRAND !== 'luxuryescapes' ? 'min-height: 35px;' : ''}
  }
`

const MobileHeaderItems = styled(Group)`
  justify-self: flex-end;

  ${mediaQueryUp.desktop} {
    display: none;
  }

  @media print {
    display: none;
  }
`

const DesktopNavigationHeader = styled(NavigationHeader)`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
  }
`

const MobileNavMenuToggle = styled(IconButton)`
  /* Negative margin-right to offset the button's padding, so that the grid gap remains visually consistent */
  margin-right: ${rem(-16)};

  ${mediaQueryUp.largeDesktop} {
    display: none;
  }

  @media print {
    display: none;
  }
`
const DesktopSearchBarContainer = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  min-width: ${rem(280)};
  margin: 0 auto;

  ${mediaQueryUp.desktop} {
    width: auto;
    max-width: ${rem(440)};
  }

  @media print {
    display: none;
  }
`

const MobileSearchBarContainer = styled.div`
  padding: ${rem(4)} 0 ${rem(12)};

  @media print {
    display: none;
  }
`

const IS_MOBILE_SEARCH_BAR_ENABLED = isMobileSearchEnabled() && config.businessTraveller.currentAccountMode !== 'business'
const IS_DESKTOP_SEARCH_BAR_ENABLED = isSearchEnabled() && config.businessTraveller.currentAccountMode !== 'business'
const HIDE_MOBILE_HAMBURGER_MENU = config.MOBILE_HIDE_HAMBURGER

interface MappedProps {
  isLoggedIn: boolean
  location: Location
  canRedeemLuxPlusBenefits: boolean
  isFreePreviewLuxPlusMember: boolean
  isMultiItemCart: boolean
}

interface Props {
  className?: string
  style?: React.CSSProperties
}

const HeaderBar = React.forwardRef<HTMLDivElement, MappedProps & Props>((props, ref) => {
  const {
    location,
    isLoggedIn,
    className,
    style,
    canRedeemLuxPlusBenefits,
    isFreePreviewLuxPlusMember,
    isMultiItemCart,
  } = props
  const containerRef = useRef<HTMLDivElement>(null)
  useAppHeaderObserver(containerRef)
  const shouldReduceSearchBarToIcon = location.pathname.endsWith('/search')

  const showDesktopSearchBar = IS_DESKTOP_SEARCH_BAR_ENABLED

  const shouldRemoveSearchButton = useSlimMobileSearch()
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const currentSearchVerticalPage = useCurrentGlobalSearchVerticalPage()
  const globalSearchVerticalTabs = useGlobalSearchVerticalTabs()
  const isFlightsSearchResults = location.pathname.includes('/flights-search-results')
  const showMobileSearchBar =
    IS_MOBILE_SEARCH_BAR_ENABLED &&
    !shouldReduceSearchBarToIcon &&
    !shouldRemoveSearchButton &&
    !isFlightsSearchResults

  const handleSearchButtonClick = useCallback(() => {
    if (!currentSearchVerticalPage) {
      toggleSearchVertical([...globalSearchVerticalTabs.keys()][0])
    } else {
      toggleSearchVertical(currentSearchVerticalPage)
    }
    addGTMEvent(searchNavCTAClick())
  }, [currentSearchVerticalPage, globalSearchVerticalTabs, toggleSearchVertical])

  const showModal = useContext(ModalContext)

  const openModal = useCallback(() => {
    showModal(<NavigationMenu />)
  }, [showModal])

  const onMyEscapesClick = () => {
    Analytics.trackClientEvent({
      subject: 'my_escapes',
      action: 'click',
      category: 'logging',
      type: 'interaction',
    })
  }

  useImperativeHandle(ref, () => containerRef.current!)

  return <HeaderBarSheet style={style} ref={containerRef} className={className}>
    <HeaderContainer>
      <MainHeader className={cn({
        'search-enabled': showDesktopSearchBar,
        'hide-hamburger': HIDE_MOBILE_HAMBURGER_MENU,
        'active-lux-plus-member': canRedeemLuxPlusBenefits || isFreePreviewLuxPlusMember,
      })}
      >
        {!HIDE_MOBILE_HAMBURGER_MENU && <MobileNavMenuToggle
          kind="tertiary"
          onClick={openModal}
          size="large"
          variant="dark"
          horizontalOutdent="start"
          data-testid="MobileNavMenuToggle"
        >
          <LineBarsIcon />
        </MobileNavMenuToggle>}
        <Group direction="horizontal" verticalAlign="center" gap={8} desktopGap={12}>
          <HeaderLogo tabletVariant="icon" mobileVariant="icon" />
          <CSSBreakpoint min="tablet">
            {canRedeemLuxPlusBenefits && <LuxPlusLabel type="plus" data-testid="lux-plus-label-header-member" to={`/${LUXURY_PLUS_PATHS.LANDING_PAGE}`} />}
            {isFreePreviewLuxPlusMember && <LuxPlusLabel type="plus-preview" data-testid="lux-plus-label-header-free-preview" to={`/${LUXURY_PLUS_PATHS.LANDING_PAGE}`} />}
          </CSSBreakpoint>
        </Group>
        {showDesktopSearchBar && (
          <CSSBreakpoint min="tablet">
            <DesktopSearchBarContainer>
              <SearchToggle />
            </DesktopSearchBarContainer>
          </CSSBreakpoint>
        )}
        <MobileHeaderItems direction="horizontal" gap={8} verticalAlign="center">
          <Group direction="horizontal" verticalAlign="center">
            <TripGuard>
              <NavigationHeaderTripsMenu size="small" />
            </TripGuard>
            {!config.businessTraveller.isEnabled && !config.agentHub.isEnabled && <TripGuard inverse>
              <HeaderContactOptionsMenu size="small" />
            </TripGuard>}
            {isLoggedIn && config.businessTraveller.isEnabled && (
              <TextButton
                size="small"
                startIcon={<LineSuitcaseIcon colour="primary"/>}
                kind="tertiary"
                variant="dark"
                onClick={onMyEscapesClick}
                to="/account/my-escapes"
              >
                My {capitalise(config.brandConfig.purchaseSynonym)}s
              </TextButton>
            )}
            {shouldReduceSearchBarToIcon && !shouldRemoveSearchButton && <IconButton
              size="small"
              kind="primary"
              shape="circle"
              onClick={handleSearchButtonClick}
            >
              <LineSearchIcon />
            </IconButton>}
            {config.agentHub.isEnabled && <AgentHubAccountMenu />}
            {config.businessTraveller.isEnabled && <BusinessTravellerAccountIconButtonMenu size="small" />}
          </Group>
          {isMultiItemCart && isLoggedIn && <MultiUserCartHeader />}
          {config.NOTIFICATION_CENTRE_ENABLED && <HeaderNotification />}
          {config.header.standAloneAccount && !config.businessTraveller.isEnabled &&
            <ProductPaletteProvider palette={canRedeemLuxPlusBenefits ? 'lux-plus' : 'default'}>
              <HeaderUserAvatar size="small" />
            </ProductPaletteProvider>
          }
        </MobileHeaderItems>
        <DesktopNavigationHeader isLoggedIn={isLoggedIn} />
      </MainHeader>
      {showMobileSearchBar && (
        <CSSBreakpoint max="mobile">
          <MobileSearchBarContainer>
            <SearchToggle />
          </MobileSearchBarContainer>
        </CSSBreakpoint>
      )}
    </HeaderContainer>
  </HeaderBarSheet>
})

HeaderBar.displayName = 'HeaderBar'

export default connect((state: App.State): MappedProps => ({
  isLoggedIn: selectLoggedIn(state),
  location: state.router.location,
  canRedeemLuxPlusBenefits: checkCanRedeemLuxPlusBenefits(state),
  isFreePreviewLuxPlusMember: isFreePreviewLuxPlusMember(state),
  isMultiItemCart: isMultiCartEnabled(state),
}), null, null, { forwardRef: true })(HeaderBar)
