import Badge from 'components/Luxkit/Badge'
import LineEnvelopeIcon from 'components/Luxkit/Icons/line/LineEnvelopeIcon'
import LineGiftIcon from 'components/Luxkit/Icons/line/LineGiftIcon'
import LineGlobeAltIcon from 'components/Luxkit/Icons/line/LineGlobeAltIcon'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import NavBarListItem from 'components/NavKit/NavBarListItem'
import config from 'constants/config'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppSelector } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'
import {
  hasIncompleteInformation as getHasIncompleteInformation,
  hasUserPreferencesNotification as getHasUserPreferencesNotification,
} from 'selectors/accountSelectors'
import NavBarList from '../NavBarList'
import NavBarListItemDivider from '../NavBarListItemDivider'
import NavBarListItemIcon from '../NavBarListItemIcon'
import AccountMenuCreditListItem from './AccountMenuCreditListItem'
import AccountMenuDrawerHeader from './AccountMenuDrawerHeader'
import AccountMenuLogoutListItem from './AccountMenuLogoutListItem'
import AccountMenuLuxPlusDetailsTile from './AccountMenuLuxPlusDetailsTile'
import AccountMenuMyEscapesListItem from './AccountMenuMyEscapesListItem'
import AccountMenuTripPlannerListItem from './AccountMenuTripPlannerListItem'

function AccountMenuDrawer() {
  const modalElement = useModalElementContext()
  const handleLinkItemClick = useCallback(() => {
    modalElement.resolve()
  }, [modalElement])

  const isStoreMode = useAppSelector(state => state.system.storeMode)
  const referralProgramEnabled = useAppSelector(state => state.auth.account.referralProgramEnabled)
  const hasIncompleteInformation = useAppSelector(getHasIncompleteInformation)
  const hasUserPreferencesNotification = useAppSelector(getHasUserPreferencesNotification)

  return <ModalBase mode="drawer-starboard">
    <AccountMenuDrawerHeader />
    <ModalBody>
      <ModalContent>
        <AccountMenuLuxPlusDetailsTile onLinkClick={handleLinkItemClick} />
      </ModalContent>
      <ModalContent flush>
        <NavBarList>
          <AccountMenuMyEscapesListItem onClick={handleLinkItemClick} />
          {!isStoreMode && <AccountMenuTripPlannerListItem onClick={handleLinkItemClick} />}
          <NavBarListItemDivider />
          <NavBarListItem
            startIcon={<NavBarListItemIcon Icon={LineUserIcon} />}
            title="My profile"
            to="/account/details"
            onClick={handleLinkItemClick}
            endIcon={hasIncompleteInformation ? <Badge kind="dot" mode="critical" /> : undefined}
          />
          {!isStoreMode && <>
            <NavBarListItem
              startIcon={<NavBarListItemIcon Icon={LineUsersAltIcon} />}
              title="Travel companions"
              to="/account/travel-companions"
              onClick={handleLinkItemClick}
            />
            {config.USER_PREFERENCES_ENABLED && <NavBarListItem
              startIcon={<NavBarListItemIcon Icon={LineGlobeAltIcon} />}
              title="Travel preferences"
              to="/account/travel-preferences"
              onClick={handleLinkItemClick}
              endIcon={hasUserPreferencesNotification ? <Badge kind="dot" mode="critical" /> : undefined}
            />}
            {config.hasSubscriptions && <NavBarListItem
              startIcon={<NavBarListItemIcon Icon={LineEnvelopeIcon} />}
              title="Communication preferences"
              to="/account/communication-preferences"
              onClick={handleLinkItemClick}
            />}
            <AccountMenuCreditListItem onClick={handleLinkItemClick} />
          </>}
          {!isStoreMode && config.hasReferralProgram && referralProgramEnabled && <>
            <NavBarListItemDivider />
            <NavBarListItem
              startIcon={<NavBarListItemIcon Icon={LineGiftIcon} />}
              title="Refer a friend"
              to="/refer"
              onClick={handleLinkItemClick}
            />
          </>}
          <NavBarListItemDivider />
          <AccountMenuLogoutListItem onClick={handleLinkItemClick} />
        </NavBarList>
      </ModalContent>
    </ModalBody>
  </ModalBase>
}

export default AccountMenuDrawer
